:root {
  --cool-blue: #6488ea;
}

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  background: #000;
  font-family: sans-serif;
  overflow: hidden;
}

button, input {
  color: inherit;
  border: none;
  font-family: inherit;
}

button {
  cursor: pointer;
}

#canvas {
  z-index: 0;
  position: absolute;
}

.hud {
  z-index: 1;
  margin: .5rem;
  position: absolute;
}
/*# sourceMappingURL=index.b487b98a.css.map */
